    <template>
    <div class="col-md-12">
        <div class="card">
            <div class="overlay" v-if="isLoading">
                <font-awesome-icon icon="sync-alt" class="fa-3x fa-refresh fa-spin" />
            </div>
            <div class="card-header border-0">
                <div class="d-flex justify-content-between">
                    <h3 class="card-title">Redações corrigidas</h3>
                    <router-link to="/relatorios/corrigidas" class="nav-link">
                        <font-awesome-icon icon="clipboard-check" class="nav-icon" /> Ver relatório
                    </router-link>
                </div>
            </div>
            <div class="card-body">
                <div class="position-relative mb-4">
                    <canvas id="visitors-chart" height="300"></canvas>
                </div>
                <div class="d-flex flex-row justify-content-center">
                    <p class="lead">Período de {{ fromDate }} até {{ toDate }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart, registerables } from 'chart.js'
import axios from "axios";
import { GRAPH_ESSAYS_CORRECTED } from '../../constants/api'

export default {
    data() {
        return  {
            chart: {},
            isLoading: true,
            fromDate: '00/00/0000',
            toDate: '00/00/0000'
        }
    },

    created() {
        Chart.register(...registerables)
         axios.get(GRAPH_ESSAYS_CORRECTED, { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }).then(({ data }) => {
            this.createChart(data.labels, data.data)
            this.isLoading = false
            this.fromDate = data.fromDate
            this.toDate = data.toDate
        });

    },

    methods: {

        createChart(labels, data) {
            labels,data
            var mode = 'index'
            var intersect = true

            var $visitorsChart = document.getElementById('visitors-chart').getContext('2d');
            this.chart = new Chart($visitorsChart, {
                data: {
                    labels: labels,
                    datasets: [
                        {
                            type: 'line',
                            label: 'Redações',
                            data: data,
                            backgroundColor: '#007bff',
                            borderColor: '#007bff',
                            pointBorderColor: '#007bff',
                            pointBackgroundColor: '#007bff',
                            fill: true,
                            tension: 0.5
                        }
                    ]
                },
                options: {
                    maintainAspectRatio: false,
                    tooltips: {
                        mode: mode,
                        intersect: intersect
                    },
                    hover: {
                        mode: mode,
                        intersect: intersect
                    },
                    legend: {
                        display: false
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false,
                            },

                            ticks: {
                                display: true
                            }
                        },
                        y: {
                            grid: {
                                display: false,
                            },

                            ticks: {
                                display: true
                            }
                        }
                    }
                }
            })
        }
    }
}
</script>