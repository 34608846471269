<template>
    <div class="col-md-12">
        <div class="card">
            <div class="card-body box-profile">
                <div class="text-center">
                    <img class="profile-user-img img-fluid img-circle" :src="avatar" alt="User profile picture">
                </div>
                <h3 class="profile-username text-center">{{ name }}</h3>
                <p class="text-center text-bold"><font-awesome-icon icon="star" class="nav-icon star"/> {{ grade }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Sidebar',
    data() {
        return {
            avatar: '../../assets/avatar.png',
            name: '',
            grade: 0.00
        }
    },
    created() {
        var data = JSON.parse(window.localStorage.getItem('corrector'))
        this.avatar = data.avatar
        this.name = data.name
    },
    methods: {
        isActive(routeName) {

            if(this.$route.matched.some(({ name }) => name === routeName)) {
                return 'active'
            }

            return ''
        }
    }
}
</script>