<template>
    <div>
        <div class="info-box mb-3">
            <span class="info-box-icon elevation-1" :class="background">
                <font-awesome-icon :icon="icon" />
            </span>
            <div class="info-box-content">
                <span class="info-box-text">{{ title }}</span>
                <span class="info-box-number">{{ description }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SmallBox',
    props: ["icon", 'title', 'description', 'background']
}
</script>