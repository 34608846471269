import axios from "axios"
import { API_BASE_URL } from '../constants/api'

const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
})

export default api;