<template>
    <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Dashboard</h1>
                    </div>
                </div>
            </div>
        </div>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <ProfileIndex />
                    <InfoBoxV2 class="col-md-6" :background="'bg-success'" :description="corrected" title="Redações corrigidas este mês" icon="file-alt" />
                    <InfoBoxV2 class="col-md-6" :background="'bg-danger'" :description="averageCorrectionTime" title="Tempo médio de correção" icon="clock" />
                    <GraphIndex />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ProfileIndex from './partials/ProfileIndex.vue'
import InfoBoxV2 from './partials/InfoBoxV2.vue'
import GraphIndex from './partials/GraphIndex.vue'
import { CORRECTOR_GET_INFO_DASHBOARD } from '../constants/api'
import api from '../services/api'

export default {
    components: { ProfileIndex, GraphIndex, InfoBoxV2 },
    data() {
        return {
            corrected: 0,
            averageCorrectionTime: '00:00:00',
        }
    },
    created() {
        api.get(CORRECTOR_GET_INFO_DASHBOARD).then((response) => {

            this.averageCorrectionTime = response.data.avgTime[0]
            this.corrected = response.data.essaysCorrected[0]

        })
    }
}
</script>
